
import React from 'react'
import Header from './Header'
import header_bg from '../assets/images/header/header_bg.png'
import logoIcon from '../assets/images/header/logo.png'
import { useAccount } from 'wagmi'
export default function BgHeader () {
  const { address } = useAccount()
  return (
    <div
      className=" pb-5"
      style={{
        // backgroundImage: `url(${header_bg})`,
        // backgroundSize: '100% 100%',
      }}>
      {/* <img src={header_bg} alt="" /> */}
      <Header></Header>
      {/* <div className="w-screen h-[5rem]  flex justify-center">
        <div className="max-w-[20.4375rem] w-[calc(100%-2rem)] flex justify-between py-2">
          <div className="mt-2 text-[1.5rem] text-[#121826] font-bold flex items-center">
            Hi,{address?.replace(/(.{3}).{35}(.{4})/, '$1****$2')}
          </div>
          <div>
            <img
              src={logoIcon}
              className="w-[4.3125rem] h-[4.3125rem]"
              alt=""
            />
          </div>
        </div>
      </div> */}
    </div>
  )
}
