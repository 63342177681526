import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux'
import { show } from '../../store/modules/setupStore'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CloseIcon from '../../assets/images/setup/icClose.svg'
import TableIconWhite from '../../assets/images/setup/table_settings_white.svg'
import ChartIconWhite from '../../assets/images/setup/Chart_white.svg'
import EqualizerIconWhite from '../../assets/images/setup/equalizer_white.svg'
import MineWhite from '../../assets/images/setup/mine_white.svg'
import MineBlack from '../../assets/images/setup/mine_black.svg'
import ITOIconWhite from '../../assets/images/setup/ITO_white.svg'
import WalletIconWhite from '../../assets/images/setup/wallet_white.svg'
import TableIconBlack from '../../assets/images/setup/table_settings_black.svg'
import ChartIconBlack from '../../assets/images/setup/Chart_black.svg'
import EqualizerIconBlack from '../../assets/images/setup/equalizer_black.svg'
import ITOIconBlack from '../../assets/images/setup/ITO_black.svg'
import WalletIconBlack from '../../assets/images/setup/wallet_black.svg'
import Bubble_white from '../../assets/images/setup/Bubble_white.svg'
import Bubble_black from '../../assets/images/setup/Bubble_black.svg'
import team_white from '../../assets/images/setup/team_white.svg'
import team_black from '../../assets/images/setup/team_black.svg'
import foundation_black from '../../assets/images/setup/foundationBlack.svg'
import foundation_white from '../../assets/images/setup/foundationWhite.svg'
import { useAccount } from 'wagmi'
export default function Setup () {
  const { t } = useTranslation()
  const { address } = useAccount()
  const [open, setOpen] = useState(false)
  const setup = useSelector(state => state.setupStore.show)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const toogleHiddle = () => {

    dispatch(show())
  }
  useEffect(() => {
    setOpen(setup)
  }, [setup])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={toogleHiddle}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full   sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-screen overflow-hidden">

                  <div className="flex w-full h-full flex-col overflow-y-scroll bg-transparent bg-opacity-90 backdrop-blur-3xl pt-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">

                        <div className='flex justify-between max-w-[20.4375rem] w-[calc(100%-2rem)] py-2'>
                          <div className=' flex items-center'>
                            <div className='flex '>

                            </div>
                            <div className='ml-2 text-[1.5rem] text-[#fff] font-bold'>Hi,{address?.replace(/(.{3}).{35}(.{4})/, '$1****$2')}</div>
                          </div>
                        </div>
                        <div className=" -translate-x-2 flex h-10 items-center">
                          <button
                            type="button"
                            className="rounded-md  text-gray-700   hover:text-gray-700 focus:outline-none "
                            onClick={toogleHiddle}
                          >
                            <img src={CloseIcon} alt="" />

                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-[0.56] flex-1  sm:px-6 ">
                      {/* Your content */}
                      <div className=''>
                        <div className='flex justify-center relative mt-8'>
                          <img className='w-[131px] h-[144px]' src={'/img/setup/logo.png'} alt="" />
                          <img className='w-[190px] h-[61px] absolute bottom-7' src={'/img/setup/nfc.png'} alt="" />
                        </div>
                        <div className="mt-[1.31rem] flex justify-center items-center">
                          <div className='w-[21.5rem]  border  rounded-lg shadow-xl'>

                            <div className={`rounded-t-lg border  h-[3rem] flex justify-center items-center ${location.pathname === '/home' ? 'bg-gradient-to-b from-[#89C6FF] to-[#3633D0] text-white' : 'text-white bg-[#ffffff1A]'}`}
                              onClick={() => {
                                navigate('/home')
                                toogleHiddle()
                              }}
                            >
                              {location.pathname === '/home' ? <img className='w-[24px] h-[24px]' src={'/img/setup/home.png'} alt="" /> : <img className='w-[24px] h-[24px]' src={'/img/setup/home.png'} alt="" />}
                              <div className='ml-[0.69rem] text-[1rem]' >{t('home')}</div>
                            </div>
                            <div className={`rounded-b-lg border  h-[3rem] flex justify-center items-center ${location.pathname === '/nftCasting' ? 'bg-gradient-to-b from-[#89C6FF] to-[#3633D0]  text-white' : 'text-white bg-[#ffffff1A]'}`}
                              onClick={() => {
                                navigate('/nftCasting')
                                toogleHiddle()
                              }}
                            >
                              {location.pathname === '/nftCasting' ? <img className='w-[23px]' src={'/img/setup/mint.png'} alt="" /> : <img className='w-[23px]' src={'/img/setup/mint.png'} alt="" />}

                              <div className='ml-[0.69rem] text-[1rem]'>{t('nftMinting')}</div>
                            </div>
                            <div className={`rounded-b-lg border  h-[3rem] flex justify-center items-center ${location.pathname === '/myAI' ? 'bg-gradient-to-b from-[#89C6FF] to-[#3633D0]  text-white' : 'text-white bg-[#ffffff1A]'}`}
                              onClick={() => {
                                // navigate('/myAI')
                                // toogleHiddle()
                              }}
                            >
                              {location.pathname === '/myAI' ? <img className='w-[20px] h-[20px]' src={'/img/setup/Vector.png'} alt="" /> : <img className='w-[20px] h-[20px]' src={'/img/setup/Vector.png'} alt="" />}
                              <div className='ml-[0.69rem] text-[1rem]' >{t('nftMarketplace')}</div>
                            </div>
                            {/* <div className={`rounded-b-lg border  h-[3rem] flex justify-center items-center ${location.pathname === '/nftTokenMining' ? 'bg-[#FEBA00]  text-white' : ''}`}
                              onClick={() => {
                                navigate('/ico')
                                toogleHiddle()
                              }}
                            >
                              {location.pathname === '/ico' ? <img src={ITOIconWhite} alt="" /> : <img src={ITOIconBlack} alt="" />}
                              <div className='ml-[0.69rem] text-[1rem]'>{t('privatePlacement')}</div>
                            </div> */}
                            <div className={`rounded-b-lg border  h-[3rem] flex justify-center items-center ${location.pathname === '/record' ? 'bg-gradient-to-b from-[#89C6FF] to-[#3633D0]  text-white' : 'text-white bg-[#ffffff1A]'}`}
                              onClick={() => {
                                navigate('/record')
                                toogleHiddle()

                              }}
                            >
                              {location.pathname === '/record' ? <img className='w-[22px] h-[22px]' src={'/img/setup/record.png'} alt="" /> : <img className='w-[22px] h-[22px]' src={'/img/setup/record.png'} alt="" />}
                              <div className='ml-[0.69rem] text-[1rem]' >{t('recording')}</div>
                            </div>
                            <div className={`rounded-b-lg border  h-[3rem] flex justify-center items-center ${location.pathname === '/myAssets' ? 'bg-gradient-to-b from-[#89C6FF] to-[#3633D0]  text-white' : ' text-white bg-[#ffffff1A]'}`}
                              onClick={() => {
                                navigate('/myAssets')
                                toogleHiddle()
                              }}
                            >
                              {location.pathname === '/myAssets' ? <img className='w-[20px] h-[20px]' src={'/img/setup/myAssets.png'} alt="" /> : <img className='w-[20px] h-[20px]' src={'/img/setup/myAssets.png'} alt="" />}

                              <div className='ml-[0.69rem] text-[1rem]'>{t('MyAssets')}</div>
                            </div>
                            {/* <div className={`rounded-b-lg border  h-[3rem] flex justify-center items-center ${location.pathname === '/myCom' ? 'bg-gradient-to-b from-[#89C6FF] to-[#3633D0]  text-white' : ''}`}
                              onClick={() => {
                                navigate('/myCom')
                                toogleHiddle()
                              }}
                            >
                              {location.pathname === '/myCom' ? <img src={team_white} alt="" /> : <img src={team_black} alt="" />}

                              <div className='ml-[0.69rem] text-[1rem]'>{t('myCommunity')}</div>
                            </div> */}

                            {/* <div className={`rounded-b-lg border  h-[3rem] flex justify-center items-center ${location.pathname === '/myNboBonusPlan' ? 'bg-[#FEBA00]  text-white' : ''}`}
                              onClick={() => {

                                navigate('/myNboBonusPlan')
                                toogleHiddle()
                              }}
                            >
                              {location.pathname === '/myNboBonusPlan' ? <img src={MineWhite} alt="" /> : <img src={MineBlack} alt="" />}
                              <div className='ml-[0.69rem] text-[1rem]'>{t('myNboBonusPlan')}</div>
                            </div> */}
                            {/* <div className={`rounded-b-lg border  h-[3rem] flex justify-center items-center ${location.pathname === '/recordList' ? 'bg-gradient-to-b from-[#89C6FF] to-[#3633D0]  text-white' : ''}`}
                              onClick={() => {

                                navigate('/recordList')
                                toogleHiddle()
                              }}
                            >
                              {location.pathname === '/recordList' ? <img src={EqualizerIconWhite} alt="" /> : <img src={EqualizerIconBlack} alt="" />}
                              <div className='ml-[0.69rem] text-[1rem]'>{t('AssetsRecord')}</div>
                            </div> */}
                          </div>
                        </div>





                      </div>
                    </div>
                  </div>
                </Dialog.Panel>


              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
